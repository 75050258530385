<!--
   首页

   @date:2022/11/21
   @author 金星晖<xinghui0928@163.com, www.xinghuijin.com>
   @copyright 南京云柜<yun-gui.com>
-->
<style lang="less">
  .simplify-theme.simplify-theme-dark {
    #dashboard {
      .home-top-bg {
        //filter: invert(80%) hue-rotate(180deg) brightness(80%);
        filter: invert(150%) hue-rotate(180deg);
        .home-bg-img {
          //filter: invert(150%) hue-rotate(180deg);
          //filter: hue-rotate(100deg);
          //filter: invert(80%) hue-rotate(180deg) brightness(80%);
          //filter: brightness(80%) !important;
        }
      }
      .no-permission-data {
        filter: invert(150%) hue-rotate(180deg);
      }
    }
  }
  #dashboard {
    margin: -15px;
    position: relative;
    @dashboardBg: #e3ecfc;
    //height: calc(100% + 15px);
    display: flex;
    flex-direction: column;
    min-width: 800px;
    height: 100%;
    .home-top-bg {
      padding: 30px 0 0 50px;
      position: relative;
      flex: 1;
      min-height: 180px;
      max-height: 180px;
      width: 100%;
      background: @dashboardBg;
      overflow: hidden;
      .home-bg-img {
        position: absolute;
        top: 0;
        right: -200px;
        bottom: 0;
        height: 180px;
      }
      .home-top-inner {
        padding-top: 5px;
        position: relative;
        z-index: 1;
        .top-title {
          font-size: 30px;
          line-height: 30px;
          font-weight: 600;
          //color: rgb(100,100,100);
          //color: white;
          //color: @themeColor;
          text-shadow: 2px 2px rgba(255,255,255,0.6);
        }
        .top-title-info {
          //padding-left: 5px;
          margin-top: 5px;
          font-size: 16px;
          line-height: 20px;
          color: @textColorLight;
          text-shadow: 2px 2px rgba(255,255,255,0.6);
          //font-style:italic;
        }
        .top-btn {
          margin-top: 15px;
          height: 36px;
          box-shadow: 0 0 10px 3px rgba(84,110,253,0.5);
        }
      }
    }
    .dashboard-data {
      display: flex;
      padding: 0 @containerGap;
      padding-top: @containerGap;
      flex: 1;
      .dashboard-left {
        flex: 1;
        max-width: 50%;
        min-width: 500px;
        //.flex-grow(500px);
        //padding: @containerGap;
        //border-radius: @borderRadiusMid;
        //background-color: @dashboardBg;
        //background-color: @backColorNormal;
        height: 100%;
        .core-data-top {
          margin: -7px -7px 8px;
          //margin-top: -7px;
          //margin-bottom: -7px;
          //padding: 0 8px;
          display: flex;
          .core-data-row1-item {
            padding: 7px;
            flex: 1;
            .inner-item {
              padding: @containerGap;
              .display-flex();
              justify-content: flex-start;
              background-color: @backColorNormal;
              border-radius: @borderRadiusMid;
              //border: 1px solid @dashboardBg;
              .img-outer {
                .display-flex();
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background-color: @backColorStrong;
                &.img-1 {
                  background-color: rgba(84,110,253,0.2);
                  //box-shadow: 0 0 6px 1px rgba(84,110,253,0.3);
                  img {
                    width: 24px;
                    height: 24px;
                  }
                }
                &.img-2 {
                  background-color: rgba(27,197,189,0.2);
                  //box-shadow: 0 0 6px 1px rgba(27,197,189,0.3);
                  img {
                    width: 28px;
                    height: 28px;
                  }
                }
                &.img-3 {
                  background-color: rgba(254,85,113,0.2);
                  //box-shadow: 0 0 6px 1px rgba(254,85,113,0.3);
                }
                img {
                  width: 26px;
                  height: 26px;
                }
              }
              .info-outer {
                margin-left: @containerGap;
                font-size: 20px;
                line-height: 22px;
                .value {
                  display: flex;
                  align-items: flex-end;
                  font-weight: 600;
                  span {
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 400;
                    color: @textColorLight;
                  }
                }
                .label {
                  font-size: 12px;
                  color: @textColorLight;
                }
              }
            }
          }
        }
        .core-data-row2 {
          padding: 0 @containerGap;
          background-color: @backColorNormal;
          border-radius: @borderRadiusMid;
          .core-data-row2-col {
            padding: @containerGap 0;
            display: flex;
            &+.core-data-row2-col {
              border-top: 1px solid @lineColorNormal;
            }
            &.row-col-0 {
              .row2-total {
                color: @themeColor;
              }
            }
            &.row-col-1 {
              .row2-total {
                color: @finishColor;
              }
            }
            &.row-col-2 {
              .row2-total {
                color: @errorColor;
              }
            }
            .row2-total {
              padding-left: @containerGap;
              .display-flex();
              align-items: flex-start;
              flex-direction: column;
              flex: 1;
              .label {
                margin-top: 5px;
                font-size: 12px;
                opacity: 0.7;
                color: @textColorLight;
              }
              .value {
                font-size: 34px;
                line-height: 34px;
                font-weight: 600;
                //font-family: YgNumberFont;
              }
            }
            .row2-sub-box {
              .flex-grow(68%);
              display: flex;
              justify-content: space-between;
              .core-data-row2-item {
                .display-flex();
                flex-direction: column;
                .flex-grow(29.5%);
                background-color: @backColorStrong;
                height: 80px;
                border-radius: @borderRadiusMid;
                .label {
                  margin-top: 5px;
                  font-size: 12px;
                  color: @textColorLight;
                }
                .value {
                  font-size: 24px;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
      .dashboard-right {
        //margin-left: @containerGap;
        flex: 1;
        //padding: @containerGap;
        //border-radius: @borderRadiusMid;
        //background-color: @dashboardBg;
        height: 100%;
        .core-data-row2 {
          padding: @containerGap * 2 @containerGap;
          background-color: @backColorNormal;
          border-radius: @borderRadiusMid;
          .line-top-condition {
            display: flex;
            padding-left: 140px;
            .condition-item {
              .display-flex();
              margin-left: 10px;
              color: @adornColor;
              background-color: white;
              line-height: 22px;
              width: 60px;
              border: 1px solid @adornColor;
              border-radius: @borderRadiusMin;
              cursor: pointer;
              &.is-current {
                background-color: @adornColor;
                color: white;
              }
            }
          }
          .line-box-content {
            padding: @containerGap 0;
            .content-row {
              padding-bottom: 10px;
              display: flex;
              align-items: center;
              .content-row-left {
                padding: 10px @containerGap * 2 10px 0;
                .flex-grow(150px);
                color: @textColorLight;
                font-size: 16px;
                font-weight: 600;
                text-align: right;
              }
              .content-row-right {
                padding-right: 30px;
                flex: 1;
                .item-progress {
                  position: relative;
                  height: 20px;
                  background-color: white;
                  border-radius: 0 10px 10px 0;
                  .item-progress-inner {
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    background-color: rgba(84,110,253, 0.9);
                    box-shadow: 1px 2px 10px 1px rgba(84,110,253, 0.5);
                    color: @themeColor;
                    font-weight: 600;
                    border-radius: 0 10px 10px 0;
                    &.progress-color-1 {
                      //background-color: rgba(27,197,189, 1);
                      //color: @finishColor;
                    }
                    &.progress-color-2 {
                      //background-color: rgba(254,85,113, 1);
                      //color: @errorColor;
                    }
                  }
                }
                .value {
                  top: 20px;
                  right: 0;
                  position: absolute;
                  padding-left: 10px;
                  display: block;
                  line-height: 20px;
                }
              }
            }
          }
          .usage-rate {
            padding-top: @containerGap * 2;
            border-top: 1px solid @lineColorNormal;
            display: flex;
            align-items: flex-end;
            .label {
              padding-right: @containerGap * 2;
              .flex-grow(145px);
              text-align: right;
              color: @adornColor;
              font-size: 22px;
              line-height: 22px;
              font-weight: 600;
            }
            .value {
              .display-flex();
              font-size: 36px;
              line-height: 28px;
              font-weight: 600;
              span {
                font-size: 22px;
              }
            }
          }
        }
      }
    }
    .no-permission-data {
      position: relative;
      flex: 1;
      .display-flex();
      padding-top: 15vh;
      align-items: flex-start;
      z-index: 2;
      background-color: #dce8fc;
      &::after {
        content: '';
        position: absolute;
        bottom: -30px;
        height: 30px;
        width: 100%;
        background-color: #dce8fc;
      }
      img {
        position: absolute;
        top: -50px;
        width: 100%;
      }
      .no-permission-data-title {
        position: relative;
        z-index: 3;
        p {
          margin-top: 5px;
          font-size: 18px;
          line-height: 24px;
          color: @textColorLight;
          text-shadow: 2px 2px rgba(255,255,255,0.6);
        }
        .big {
          font-size: 40px;
          line-height: 40px;
          font-weight: 600;
          //color: rgb(100,100,100);
          //color: white;
          //color: @themeColor;
          text-shadow: 2px 2px rgba(255,255,255,0.6);
        }
      }
    }
  }
</style>

<template>
  <section id="dashboard">
    <div class="home-top-bg">
      <img
        src="/loginImg/homeBg4.gif"
        class="home-bg-img"
      >
      <div
        v-if="hasDataPermission"
        class="home-top-inner"
      >
        <div class="top-title">
          您好，{{ loginUser.realName }}
        </div>
        <div class="top-title-info">
          {{ homePageName }}
        </div>
        <yg-button
          type="primary"
          class="top-btn"
          @click="_clickInfo"
        >
          {{ loginUser.h5UserTypeName }}信息
        </yg-button>
      </div>
    </div>
    <div
      v-if="hasDataPermission"
      class="dashboard-data"
    >
      <div class="dashboard-left">
        <yg-card
          title="核心数据"
        >
          <div
            slot="content"
          >
            <div class="core-data-top">
              <div
                v-for="(item, i) in coreDataLayout.row1"
                :key="i"
                class="core-data-row1-item"
              >
                <div class="inner-item">
                  <div
                    class="img-outer"
                    :class="'img-' + item.color"
                  >
                    <img :src="item.icon">
                  </div>
                  <div class="info-outer">
                    <div class="value">
                      <p>
                        {{ pageCoreData[item.key] || '0' }}
                      </p>
                      <span>个</span>
                    </div>
                    <div class="label">
                      {{ item.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="core-data-row2">
              <div
                v-for="(item, i) in coreDataLayout.row2"
                :key="i"
                class="core-data-row2-col"
                :class="'row-col-' + i"
              >
                <div class="row2-total">
                  <div class="value">
                    {{ pageCoreData[item.total.key] || '0' }}
                  </div>
                  <div class="label">
                    {{ item.total.label }}
                  </div>
                </div>
                <div class="row2-sub-box">
                  <div
                    v-for="(sub, s) in item.items"
                    :key="'s-' + s"
                    class="core-data-row2-item"
                    :style="!sub.key ? 'opacity: 0' : ''"
                  >
                    <div class="value">
                      {{ pageCoreData[sub.key] || '0' }}
                    </div>
                    <div class="label">
                      {{ sub.label }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </yg-card>
      </div>
      <div class="dashboard-right">
        <yg-card
          title="经营数据"
        >
          <div
            slot="content"
            class="core-data-row2"
          >
            <div class="line-top-condition">
              <div
                v-for="(item, i) in businessLayout.headers"
                :key="i"
                class="condition-item"
                :class="businessLayout.headerIndex === i ? 'is-current' : ''"
                @click.stop="_clickDay(i)"
              >
                {{ item.label }}
              </div>
            </div>
            <div class="line-box-content">
              <div
                v-for="(item, i) in businessLayout.items"
                :key="i"
                class="content-row"
              >
                <div class="content-row-left">
                  {{ item.label }}
                </div>
                <div class="content-row-right">
                  <div class="item-progress">
                    <p
                      class="item-progress-inner"
                      :class="'progress-color-' + i"
                      :style="`width:${pageBusinessData[item.key].percent}%`"
                    >
                      <span class="value">{{ pageBusinessData[item.key].value }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="usage-rate">
              <p class="label">
                端口使用率
              </p>
              <p class="value">
                {{ portUsageRate }}
                <span>%</span>
              </p>
            </div>
          </div>
        </yg-card>
      </div>
    </div>
    <div
      v-else
      class="no-permission-data"
    >
      <img
        src="/loginImg/homeTransferLine.png"
        alt=""
      >
      <div class="no-permission-data-title">
        <p class="big">
          您好，{{ loginUser.realName }}
        </p>
        <p>欢迎登录智能充电管理平台</p>
      </div>
    </div>
  </section>
</template>

<script>
import HomeApiSet from '@/request/api/HomeApiSet';
export default {
  components: { },
  data () {
    return {
      loginUser: {},
      homePageName: '',
      // 核心数据
      hasDataPermission: false,
      pageCoreData: {},
      coreDataLayout: {
        row1: [
          { label: '物业公司', key: 'propertyCompanyCount', icon: '/icon/home_conpany.png', color: 1 },
          { label: '小区', key: 'communityCount', icon: '/icon/home_community.png', color: 2 },
          { label: '站点', key: 'siteCount', icon: '/icon/home_site.png', color: 3 }
        ],
        row2: [
          {
            total: { label: '充电桩设备统计', key: 'deviceCount' },
            items: [
              { label: '在线设备', key: 'onlineDeviceCount' },
              { label: '离线设备', key: 'offlineDeviceCount' },
              { label: '故障设备', key: 'troubleDeviceCount' }
            ]
          },
          {
            total: { label: '充电桩端口统计', key: 'portCount' },
            items: [
              { label: '在用端口', key: 'occupationPortCount' },
              { label: '空闲端口', key: 'leisurePortCount' },
              { label: '故障端口', key: 'troublePortCount' }
            ]
          },
          {
            total: { label: '充电订单统计(进行中）', key: 'inProgressOrderCount' },
            items: [
              { label: '充电中', key: 'chargingOrderCount' },
              { label: '待充中', key: 'waitChargeOrderCount' },
              { label: '异常订单', key: '' }
            ]
          }
        ]
      },
      businessLayout: {
        headers: [
          { label: '昨天', value: 0 },
          { label: '近7日', value: 1 },
          { label: '当月', value: 2 }
        ],
        headerIndex: 0,
        items: [
          { label: '营收金额(元)', key: 'revenueAmount' },
          { label: '订单总量(个)', key: 'orderCount' },
          { label: '交易额(元)', key: 'transAmount' }
        ]
      },
      // 经营数据
      pageBusinessData: null,
      portUsageRate: ''
    };
  },
  created () {
    const merchantInfo = this.$storage.merchantInfo;
    const userInfo = this.$storage.user;
    this.homePageName = merchantInfo.propertyCompanyName || merchantInfo.providerName;
    this.loginUser = userInfo;
    // this.$store.dispatch('checkPermission', [
    //   'system:homePage:coreData',
    //   'system:homePage:businessData'
    // ]).then(res => {
    //   if (res) {
    //     this.requestCoreData();
    //     this.requestBusinessData();
    //   }
    // });
  },
  methods: {
    _clickInfo () {
      this.$router.push({
        name: 'system_merchantInfo'
      });
    },
    _clickDay (i) {
      if (this.businessLayout.headerIndex === i) return;
      this.businessLayout.headerIndex = i;
      const item = this.businessLayout.headers[i];
      console.log(item);
      this.requestBusinessData(item.value);
    },
    // 请求核心数据
    requestCoreData () {
      const api = HomeApiSet.coreData;
      api.autoConfig.autoToast = false;
      api.params = {
        userType: this.$storage.user.userType
      };
      this.$http(api).then(res => {
        this.pageCoreData = res.data;
        this.hasDataPermission = true;
      });
    },
    // 请求经营数据
    requestBusinessData (days = '0') {
      const api = HomeApiSet.businessData;
      api.autoConfig.autoToast = false;
      api.params = {
        userType: this.$storage.user.userType,
        days: days + ''
      };
      this.$http(api).then(res => {
        const resData = res.data;
        // const resData = {
        //   revenueAmount: 11431.16,
        //   orderCount: 9231,
        //   transAmount: 12031.88
        // };
        this.portUsageRate = resData.portUsageRate || '0';
        const keyArr = ['revenueAmount', 'orderCount', 'transAmount'];
        let max = 0;
        keyArr.forEach(v => {
          const num = resData[v];
          if (max < num) {
            max = num;
          }
        });
        const pageBusinessData = {};
        keyArr.forEach(v => {
          const num = resData[v];
          const t = num / max * 100;
          pageBusinessData[v] = {
            value: num,
            percent: Number.isNaN(t) ? 0 : t
          };
        });
        this.pageBusinessData = pageBusinessData;
      });
    }
  }
};
</script>
